import React, { Component } from 'react';
import { Container } from '../containers/container';
import MembersList from './components/members_list';

import './members_page.css';
import AddMemberModal from './components/add_member';
import { MemberService } from '../../services/member_service';

class   MembersPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchText: "",
            showCreateMemberModal: false,
            notification: <></>,
            members: [],
            loading: false,
            selectedYear: "-1",
            availableYears: [],
            membersCount: 0
        }
    }

    componentDidMount() {
        this.getMembers();
    }


    getMembers() {
        this.setState({ loading: true }, () => {
            MemberService.getMembers((members) => {
                var availableYears = [];

                members.forEach(member => {
                    if (member.last_active_year !== null && !availableYears.includes(member.last_active_year)) {
                        availableYears.push(member.last_active_year);
                    } 
                })
                availableYears.sort((a, b) => a < b);
                this.setState({ members: members, loading: false, availableYears: availableYears }, () => {
                    members.forEach(member => {
                        MemberService.getMemberBalance(member.id, (balance) => {
                            member.solde = -balance;
                            this.setState({members: members});
                        }, (error) => {
                        });
                    });
                })
            }, () => {
                this.setState({ loading: false });
            });
        });
    }

    pushNotification(notification) {
        this.setState({notification: notification});
    }

    renderYearSelector() {
        const options = this.state.availableYears.map(availableYear => {
            return (<option value={availableYear}>{availableYear}</option>)
        });
        return (
            <select className="form-control" value={this.state.selectedYear} onChange={e => this.setState({selectedYear: e.target.value})}>
                <option value={"-1"}>Tous</option>
                {options}
            </select>
        )
    }

    getMembersWithActiveYear() {
        if (this.state.selectedYear !== "-1") {
            return (
                this.state.members.filter(member => {
                    return (member.last_active_year && member.last_active_year.toString() === this.state.selectedYear.toString())
                })
            )    
        }
        return this.state.members;
    } 

    getMemberFullName(member) {
        return `${member.first_name} ${member.last_name}`
    }

    getMembersList() {
        var members;

        if (this.state.searchText !== undefined && this.state.searchText !== "") {
            members = this.getMembersWithActiveYear().filter(member => {
                return (member.email.toLowerCase().includes(this.state.searchText) || this.getMemberFullName(member).toLowerCase().includes(this.state.searchText) || member.mobile_phone.toLowerCase().includes(this.state.searchText));
            });
        }
        else {
            members = this.getMembersWithActiveYear();
        }
        return members;
    }

    convertToCSV(array) {
        const keys = Object.keys(array[0]).filter((key) => key !== "picture_url");
        const csvRows = array.map((row) =>
        keys.map((key) => `"${row[key]}"`).join(";")
        );

        // Combine headers and rows
        const csvData = [keys.join(","), ...csvRows].join("\n");
        return csvData;
    }

    // Function to download CSV file
    downloadCSV = () => {
        const csv = this.convertToCSV(this.getMembersList());
        const blob = new Blob(["\uFEFF", csv], { type: "text/csv;charset=utf-8;" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `clubmanager-extract-${this.state.selectedYear !== "-1" ? this.state.selectedYear : "all"}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    render() {
        const membersList = this.getMembersList();

        return (
            <div>
                <h1> Membres </h1>
                <div className="row">
                    <Container className="col-12 members-list offset-0">
                        <div className="row">
                            <div className="col-auto">
                                {this.renderYearSelector()}
                            </div>
                            <div className="col-lg-3 col-md-8 col-sm-6 search-members-box">
                                <i className="fa fa-search"></i>
                                <input className="search-members-input" type="text" placeholder="Rechercher..." onChange={(e) => {this.setState({searchText: e.target.value})}}/>
                            </div>
                            <div className="col-auto member-count-container">
                                <p className="text-muted member-count">{membersList.length} membres</p>
                            </div>
                            <div className="col">

                            </div>
                            <div className="col-auto buttons-container">
                                <button className="btn btn-outline-secondary" onClick={() => {this.downloadCSV()}} disabled={this.state.members === undefined}>
                                    <span className='btn-label'>
                                        <i className="fas fa-file-csv"></i>
                                    </span>
                                    <span className='button-title'> Exporter vers CSV</span>
                                </button>
                                <button className="btn btn-outline-primary" onClick={() => {this.setState({showCreateMemberModal: true})}}>
                                    <span className="btn-label">
                                        <i className="fa fa-plus"></i>
                                    </span>
                                    <span className="button-title"> Nouveau </span>
                                </button>
                            </div>
                        </div>
                        <MembersList members={membersList} loading={this.state.loading}/>
                    </Container>
                </div>
                <AddMemberModal active={this.state.showCreateMemberModal} onClose={() => {this.setState({showCreateMemberModal: false}); this.getMembers()}} />
                {this.state.notification}
            </div>
        );
    }
}

export default MembersPage;