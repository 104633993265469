import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

export const HtmlEditor = (props) => {
    return (
        <Editor
            apiKey="wjvzx00qexl2md4j79k7flyphc6dhf4qnuqkisks0bft8x3g"
            value={props.content}
            init={
                {
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor textcolor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount link image table'
                    ],
                    toolbar:
                        "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link image | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
                    image_list: [],
                    language: "fr_FR",
                    placeholder: "Corps de l'article"
                }
            }
            {...props}
        />
    )

}